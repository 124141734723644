import React from "react"
import {
  Container,
  Box,
  Typography,
  useMediaQuery,
  Grid,
} from "@material-ui/core"
import { useStyles } from "./style"
import { useTheme } from "@material-ui/styles"
import Data from "../../Layout/Data"

const AboutUsLayout = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  const { team } = Data

  return (
    <Box
      id="about"
      position="relative"
      paddingTop={"10rem"}
      className={classes.root}
    >
      <Box className={classes.hero} paddingBottom="3rem">
        <Typography variant="h1" align="center" gutterBottom>
          About Us
        </Typography>
        <Typography className={classes.subTitle} align="center" gutterBottom>
          We are committed to providing access to quality education to
          {!isMobile && <br />}
          anyone who needs it.
        </Typography>
      </Box>
      <Box className={classes.body}>
        <Container maxWidth={"md"}>
          <Box className={classes.aboutDetails} align="center">
          <Typography variant="body1">
            EduCollect is an Education financing gateway that seeks to bridge
            the funding gap in the education system, by aggregating multiple
            funders to provide finance &amp; support to Students, Parents and
            Education service providers across various academic tiers in Africa.
            This is aimed at improving the literacy level in the continent.
            </Typography>

            <Typography className={classes.paragrapgh}>
            With no collateral and some of the best interest rates, EduCollect
            helps to remove the financial barrier to quality education. This
            offering is in line with{" "}
            <a href="https://edutechng.com" target="_blank" rel="noreferrer" className={classes.link}>
              EduTech’s
            </a>{" "}
            commitment to being a part of the solution to the ever present
            challenge of access to funds, by providing platforms that facilitate
            seamless access to quality and uninterrupted education.
          </Typography>

          <Typography className={classes.paragrapgh}>
            We are aware that good education isn’t cheap, and the gap keeps
            getting wider year on year. For this reason, we want to ensure that
            those with access to education are not left disenfranchised.
          </Typography>
          </Box>

          <Box className={classes.team} justifyContent="center">
            <Typography variant="h2" align="center" gutterBottom>
              Meet the Team
            </Typography>
            <Box className={classes.gallery}>
              <Grid container spacing={isMobile ? 5 : 10}>
                {team.map((member, index) => {
                  const { name, title, image } = member
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={index}
                      className={classes.member}
                    >
                      <div className={classes.imageContainer}>
                        <img
                          src={image}
                          alt={name}
                          width="100%"
                          height="100%"
                          style={{borderRadius: "5px"}}
                        />
                      </div>
                      <Box className={classes.memberDetails}>
                        <small>{name}</small>
                        <small style={{ fontSize: "11px", color: "#5DADE2" }}>
                          {title}
                        </small>
                      </Box>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default AboutUsLayout
