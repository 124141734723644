import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#F2F8FF",
  },
  body: {
    backgroundColor: "#FFFFFF",
    padding: "2rem 0 5rem 0",
    "& p, li": {
      fontWeight: 400,
      fontSize: `1rem`, //16
    },
  },
  subTitle: {
    color: "#24292E",
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    textDecoration: "none",
  },
  paragrapgh: {
    marginTop: "2rem",
  },
  team: {
    marginTop: "4rem",
  },
  gallery: {
    width: "80%",
    margin: "2rem auto",
  },
  aboutDetails: {
    width:"704px", 
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  imageContainer: {
    height: "12rem",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      margin: "0 auto",
      height: "14rem",
    },
  },
  memberDetails: {
    marginTop: "0.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}))

export { useStyles }
